import { Box, Button, Checkbox } from "@eatclub-apps/ec-component-library";
import React, { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { COLORS } from "../../../theme";
import useStyles from "./TermsAgreementStyles";

export const TermsAgreement = () => {
  const classes = useStyles();

  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const signatureCanvasRef = useRef(null);

  return (
    <>
      <Box className={classes.termsWrapper}>
        <Box>
          In the event the Services are terminated, the venue is to return all tech equipment
          (tablet and charging components) by mail to EatClub within 14 days to the address
          specified by the EatClub representative. Failure to return will result in a tablet fee
          charge of $200.
        </Box>

        <Box>
          This Agreement, which incorporates the Contract Details, Glossary and EatClub Terms &
          Conditions constitutes the entire agreement between the parties. This Agreement may be
          executed electronically and in any number of counterparts, each of which, when executed,
          is an original. Those counterparts together make one instrument. The person signing the
          Agreement for the Restaurant represents and warrants that he or she is authorized to act
          on behalf of the Restaurant for the purposes of this Agreement.
        </Box>
        <Box>
          <a
            style={{ textDecoration: "underline", color: "#313131" }}
            href="https://signup.eatclub.com.au/terms_conditions"
            target="_blank"
            rel="noreferrer noopener"
          >
            https://signup.eatclub.com.au/terms_conditions
          </a>
        </Box>
        <Checkbox
          style={{
            marginTop: "20px",
            checkbox: {
              justifyContent: "flex-start",
              alignItems: "flex-start",
            },
            checkboxBox: { width: "40px", height: "40px", minWidth: "40px", minHeight: "40px" },
            // label: { paddingTop: "4px" },
            checkMark: { width: "16px", height: "16px" },
          }}
          color={COLORS.CHARCOAL}
          label={
            <Box>
              By registering your account, you agree to our Services Agreement and the{" "}
              <a
                href="https://stripe.com/connect-account/legal/full"
                style={{
                  textDecoration: "underline",
                  color: "#313131",
                  "&:hover": { color: "black" },
                }}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => e.stopPropagation()}
              >
                Stripe Connected Account Agreement.
              </a>
            </Box>
          }
          selected={acceptedTerms}
          onChange={(value) => {
            setAcceptedTerms(value);
          }}
        />

        <Box style={{ position: "relative" }}>
          <SignatureCanvas
            ref={signatureCanvasRef}
            clearOnResize={false}
            canvasProps={{
              className: "signature-canvas",
            }}
          />
          <Box
            style={{
              position: "absolute",
              left: "50%",
              top: "90px",
              transform: "translateX(-50%)",
              opacity: "",
            }}
          >
            Signature
          </Box>
          <Button
            type="text"
            className="brand-link-color"
            text="clear signature"
            onClick={() => signatureCanvasRef.current.clear()}
          />
        </Box>
      </Box>
    </>
  );
};

TermsAgreement.propTypes = {};
