import { makeStyles } from "@mui/styles";
import { COLORS } from "../../../theme";

const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      // marginBottom: "70px",
      // maxWidth: "600px",
      // position: "fixed",
      width: "100%",
      top: 0,
      zIndex: 10,
      backgroundColor: "#F5F2ED",
      color: COLORS.JET,
    },
    topHeader: {
      height: "80px",
      paddingLeft: "28px",
      backgroundColor: "#F5F2ED",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
    },
    divider: {
      // boxSizing: "border-box",
      // width: "calc(100% - 48px)",
      margin: "0 24px",
      borderBottom: "1px solid #E6E1D7",
    },
    logo: {
      height: "34px",
      // borderBottom: "1px solid #E6E1D7",
    },
    mainHeader: {
      height: "425px",
      paddingLeft: "64px",
      paddingTop: "128px",
      backgroundColor: "#F5F2ED",

      [theme.breakpoints.down("sm")]: {
        height: "200px",
        display: "none",
      },
    },
    mainHeaderContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      gap: "54px",
    },
  }),
  { name: "Header" },
);

export default useStyles;
