import { Box } from "@eatclub-apps/ec-component-library";
import PropTypes from "prop-types";
import React from "react";
import useStyles from "./SectionStyles";

export const Section = ({ title, children, id = "", navRef }) => {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper} id={id}>
      <Box className={classes.scrollAnchor} ref={navRef?.component} />
      <Box className={classes.title}>{title}</Box>
      <Box className={classes.divider} />
      {children}
    </Box>
  );
};

Section.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element,
  id: PropTypes.string,
};
