import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(
  () => ({
    table: {
      width: "100%",
      fontSize: "16px",

      "& tbody tr:last-child": {
        borderBottom: "none",
      },

      "& thead": {
        backgroundColor: "#F5F2ED",
      },

      "& th": {
        fontWeight: "500",
      },
    },
    conditions: {
      backgroundColor: "#ECEDE6",
      padding: "24px 12px",
      borderRadius: "6px",
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },
  }),
  { name: "Services" },
);

export default useStyles;
