import { Box, TextArea } from "@eatclub-apps/ec-component-library";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { formStateType } from "../../../types";
import {
  getLabelForValue,
  setupPaymentFrequencies,
  takeawayPricingTypes,
  membershipFrequencies,
  eatclubBookingsPlans,
} from "../../../data/ValueLists";
import { formatCurrency, isEmpty } from "../../../helpers/Helpers";
import useStyles from "./ServicesStyles";

export const getBookingsFee = (plan) => {
  switch (plan) {
    case "lite":
      return "$10 per month";
    case "premium":
      return "$50 per month";
    case "bookingsOnly":
      return "$149 per month";
    default:
      return null;
  }
};

// TODO fill with actual data
export const Services = ({ signup }) => {
  const classes = useStyles();

  const useWhiteLabel =
    signup?.services?.whiteLabel?.tableOrdering ||
    signup?.services?.whiteLabel?.takeaway ||
    signup?.services?.whiteLabel?.delivery;

  const bookingsOnly =
    signup?.services?.bookings?.enabled && signup?.services?.bookings?.plan === "bookingsOnly";

  const getFeeDescription = (amount, pricingType) =>
    `${formatCurrency(amount)} ${getLabelForValue(
      pricingType,
      takeawayPricingTypes(),
    ).toLowerCase()}`;

  const getTableData = () => {
    const tableData = [];
    if (!bookingsOnly) {
      // Setup
      tableData.push({
        serviceType: "Setup",
        fees: `${formatCurrency(signup?.fees?.setup?.amount, false)} ${getLabelForValue(
          signup?.fees?.setup?.frequency,
          setupPaymentFrequencies(),
        ).toLowerCase()}`,
      });

      // Membership
      tableData.push({
        serviceType: "Membership",
        fees: `${formatCurrency(
          signup?.fees?.membership?.amount,
          false,
        )} ${getLabelForValue("monthly", membershipFrequencies()).toLowerCase()}`,
      });

      // Dine-in
      if (signup?.services?.dineIn?.dineInVouchers || signup?.services?.dineIn?.tableOrders) {
        const dineInPrice = !signup?.fees?.dineIn?.differentOnlinePricing
          ? getFeeDescription(signup.fees.dineIn?.amount, signup.fees.dineIn?.pricingType)
          : `${signup.fees.dineIn?.onlineAmount}% per online order`;

        tableData.push({
          serviceType: "Dine-in*",
          fees: dineInPrice,
        });
      }

      // Takeaway
      if (signup?.services?.takeaway?.takeawayVouchers) {
        const takeawayPrice =
          signup?.fees?.takeawayOrders?.pricingType === "percent"
            ? !signup?.fees?.takeawayOrders?.differentOnlinePricing
              ? getFeeDescription(
                  signup.fees.takeawayOrders?.amount,
                  signup.fees.takeawayOrders?.pricingType,
                )
              : `${signup.fees.takeawayOrders?.onlineAmount}% per online order`
            : `${getFeeDescription(
                signup.fees.takeawayOrders?.amount,
                signup.fees.takeawayOrders?.pricingType,
              )}

          ${
            signup?.fees?.takeawayOrders?.differentOnlinePricing &&
            `${signup.fees.takeawayOrders?.onlineAmount}% per online order`
          }`;

        // Dine-in
        tableData.push({
          serviceType: "Takeaway*",
          fees: takeawayPrice,
        });
      }

      // White label
      if (useWhiteLabel) {
        tableData.push({
          serviceType: "Order Direct - White label",
          fees: `${signup.fees.whiteLabel?.amount}% transaction fee`,
        });
      }
    }

    if (signup?.services?.bookings?.enabled) {
      tableData.push({
        serviceType: `EC Bookings: ${getLabelForValue(signup?.services?.bookings?.plan, eatclubBookingsPlans())}`,
        fees: getBookingsFee(signup?.services?.bookings?.plan),
      });
    }

    return tableData;
  };

  return (
    <>
      {/*<Box style={{ marginBottom: "55px" }}>*/}
      {/*  <p className="paragraph-large">*/}
      {/*    All fees are exclusive of GST.&nbsp;Annual fees are payable after 12 months.*/}
      {/*  </p>*/}
      {/*</Box>*/}
      <table className={classes.table}>
        <thead>
          <tr>
            <th>Service type</th>
            <th>Fees</th>
          </tr>
        </thead>
        <tbody>
          {getTableData().map((row) => (
            <tr key={row.serviceType}>
              <td>{row.serviceType}</td>
              <td>{row.fees}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Box className={classes.conditions}>
        <Box>* All in-app orders are subject to a 1.75% transaction fee.</Box>
        <Box>
          ** All in-app orders placed below the minimum spend will not be subject to the booking fee
          but will instead be charged a flat 10% on the transaction.
        </Box>
      </Box>

      {!isEmpty(signup?.fees?.pricingNotes) && <Box>{signup?.fees?.pricingNotes}</Box>}
    </>
  );
};

Services.propTypes = {
  signup: formStateType,
};
