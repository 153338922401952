import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      display: "flex",
      flexDirection: "column",
      gap: "8px",

      // [theme.breakpoints.down("sm")]: {
      //   display: "none",
      // },
    },
  }),
  { name: "NavAnchors" },
);

export default useStyles;
