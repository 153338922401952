import { ProgressBar, Button } from "@eatclub-apps/ec-component-library";
import React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { StepSelector } from "../StepSelector/StepSelector";
import LogoImage from "../../assets/logoEatClub.svg";

export const SignupHeader = ({
  step,
  screens,
  setStep,
  saveAndExit,
  logout,
  disableSaveButton,
  isComplete,
}) => {
  const totalSteps = screens.length;
  const stepNumber = screens.findIndex((screen) => screen.id === step) + 1;

  return (
    <Box className="header-wrapper">
      <Box className="header">
        <Box className="header-content">
          <Box className="header-content-left">
            <Box className="logo">
              <LogoImage style={{ height: "40px" }} />
            </Box>
            <StepSelector step={step} screens={screens} setStep={setStep} />
          </Box>
          <Box className="header-content-right">
            <Button
              type="standard"
              text={isComplete ? "Return to restaurant selection" : "Save and Exit"}
              style={{ button: { padding: "10px 20px" } }}
              onClick={saveAndExit}
              disabled={disableSaveButton}
            />
            <Button
              className="brand-link-color"
              type="text"
              text="Log out"
              style={{ paddingTop: "10px" }}
              onClick={logout}
            />
          </Box>
        </Box>
      </Box>
      {!isComplete && <ProgressBar progress={stepNumber} total={totalSteps} />}
      {isComplete && (
        <Box
          style={{
            backgroundColor: "#BCEE95",
            width: "100%",
            textAlign: "center",
            padding: "20px",
            fontWeight: "bold",
          }}
        >
          You are viewing the data for a completed signup. You will not be able to edit any details
        </Box>
      )}
    </Box>
  );
};

SignupHeader.propTypes = {
  step: PropTypes.string.isRequired,
  screens: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setStep: PropTypes.func.isRequired,
  saveAndExit: PropTypes.func.isRequired,
  isComplete: PropTypes.bool.isRequired,
};
