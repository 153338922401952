import { ThemeProvider } from "@mui/styles";
import React from "react";
import { Switch, Route } from "react-router-dom";
import { unstable_createMuiStrictModeTheme } from "@mui/material/styles";
import AppWithAuth from "../auth/AppWithAuth";
import { SignupForm } from "../components/signupForm/SignupForm";
import { AppLayout } from "../layouts/AppLayout";
import { CreditCardOverride } from "../screens/CreditCardOverride";
import { TermsConditions } from "../screens/TermsConditions";
import { UserSign } from "../screens/UserSign/UserSign";

const theme = unstable_createMuiStrictModeTheme();

export const Routes = () => (
  <Switch>
    <Route path="/cc_override">
      <AppWithAuth>
        <AppLayout>
          <CreditCardOverride />
        </AppLayout>
      </AppWithAuth>
    </Route>

    <Route path="/terms_conditions">
      <AppLayout>
        <TermsConditions />
      </AppLayout>
    </Route>

    <Route path="/finish" exact={true}>
      <AppLayout>
        <ThemeProvider theme={theme}>
          <UserSign />
        </ThemeProvider>
      </AppLayout>
    </Route>

    <Route path="/">
      <AppWithAuth>
        <AppLayout>
          <SignupForm />
        </AppLayout>
      </AppWithAuth>
    </Route>
  </Switch>
);
