import { Box, Button } from "@eatclub-apps/ec-component-library";
import PropTypes from "prop-types";
import React from "react";
import useOnScreen from "../../../helpers/Hooks/useOnScreen";
import { COLORS } from "../../../theme";
import useStyles from "./NavAnchorsStyles";

export const NavAnchor = ({ anchor, isActive = false, anchorRef }) => {
  const classes = useStyles();
  useOnScreen(anchorRef?.component, (value) => anchorRef?.onChange(value));

  return (
    <Box>
      <Button
        type="text"
        // href={`#${anchor.value}`}
        onClick={() => {
          if (anchorRef?.component?.current) {
            anchorRef.component.current.scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "nearest",
            });
          }
        }}
        newTab={false}
        style={{
          button: {
            color: isActive ? COLORS.BLACK : COLORS.SMOKE,
            userSelect: "none",
            userDrag: "none",
          },
          buttonHover: {
            color: COLORS.CHARCOAL,
          },
          link: {
            userDrag: "none",
          },
        }}
      >
        {anchor.label}
      </Button>
    </Box>
  );
};

NavAnchor.propTypes = {
  isActive: PropTypes.bool,
};
