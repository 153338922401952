import { createRef, useEffect, useRef, useState } from "react";

/**
 * Lets you use nav anchors!
 *
 * Simply pass the refs from this into your anchored components (e.g. section headings).
 * In the component use ref={navRef.component} and it should just magically work
 *
 * @param anchors
 * @returns {{onChange: updateVisibility, anchors: React.MutableRefObject<*>, activeSection: unknown}}
 */
export default function useNavAnchors(anchors) {
  const defaultAnchor = anchors?.[0]?.value;

  // Visibility for anchors
  const visibleSections = useRef([]);
  const [activeSection, setActiveSection] = useState(defaultAnchor);

  const updateVisibility = (sectionId, visible) => {
    if (visible && !visibleSections.current.includes(sectionId)) {
      // If this is now visible
      const displayOrder = anchors.map((anchor) => anchor.value);
      const newVisibleSections = [...visibleSections.current, sectionId];

      // Keep them in order so the top-most nav item is the active one
      const sortedVisibleSections = displayOrder.filter((section) =>
        newVisibleSections.includes(section),
      );

      visibleSections.current = sortedVisibleSections;
    } else if (!visible && visibleSections.current.includes(sectionId)) {
      // If this is no longer visible
      visibleSections.current = visibleSections.current.filter((section) => section !== sectionId);
    }

    setActiveSection(visibleSections.current?.[0]);
  };

  const createAnchors = (newAnchors) => {
    return newAnchors.reduce(
      (accum, anchor) => ({
        ...accum,
        [anchor.value]: {
          onChange: (visible) => {
            updateVisibility(anchor.value, visible);
          },
          component: createRef(),
        },
      }),
      {},
    );
  };

  // Refs to pass to objects for navigation
  // I'm trying to make it so all the components need to do is use the navRef in one of their children
  const anchorsRef = useRef(createAnchors(anchors));
  const anchorValues = anchors.map((anchor) => anchor.value).join("_");

  // Update the anchors if they changed
  useEffect(() => {
    anchorsRef.current = createAnchors(anchors);
  }, [anchorValues]);

  return { anchors: anchorsRef, onChange: updateVisibility, activeSection };
}
