import { Button } from "@eatclub-apps/ec-component-library";
import React, { useState, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import set from "lodash.set";
import get from "lodash.get";
import { useHistory } from "react-router-dom";
import { getDefaultFormValues } from "../../data/DefaultFormValues";
import { signOut } from "../../auth/auth-client";
import { saveSignupApplication, mapFormDataForAPI } from "../../graphql/api-client";
import CriticalWarningSVG from "../../assets/critical_warning.svg";
import { useQuery } from "../../helpers/Hooks";
import { CommunicationsPage } from "./pages/CommunicationsPage";
import { CreateRestaurant } from "./pages/CreateRestaurant";
import { DealsPage } from "./pages/DealsPage";
import { Finalise } from "./pages/Finalise";
import { NewVsExisting } from "./pages/NewVsExisting";
import { OpeningHoursPage } from "./pages/OpeningHoursPage";
import { OperationalDetailsPage } from "./pages/OperationalDetailsPage";
import { PaymentDetailsPage } from "./pages/PaymentDetailsPage";
import { Pricing } from "./pages/Pricing";
import { Services } from "./pages/Services";
import { Success } from "./pages/Success";
import { WhiteLabelSetupPage } from "./pages/WhiteLabelSetupPage";

import { SignupFooter } from "../SignupFooter/SignupFooter";
import { SignupHeader } from "../SignupHeader/SignupHeader";

// TODO this is a screen?
export const SignupForm = () => {
  const queryParams = useQuery();
  const step = queryParams.get("step") || "signup";
  // TODO for existing restaurants, pull the form state from the existing data

  const [formData, setFormData] = useState(
    JSON.parse(sessionStorage.getItem("formData")) || getDefaultFormValues,
  );
  const [isScreenValid, setIsScreenValid] = useState(false);
  const [updatedFields, setUpdatedFields] = useState([]); // Since we'll only want to submit fields that were changed
  const [submittedFields, setSubmittedFields] = useState([]); // The fields that we submitted, which we will remove from updated on successful save
  const [isSubmitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [saving, setSaving] = useState(false); // To prevent saving multiple times
  const history = useHistory();

  const isSaving = useRef(false); // Refs don't have to wait for a re-render, so this is better to prevent duplicate save clicks

  const useWhiteLabel =
    formData?.services?.whiteLabel?.tableOrdering ||
    formData?.services?.whiteLabel?.takeaway ||
    formData?.services?.whiteLabel?.delivery;

  /**
   * Updates multiple values at once.
   *
   * If trying to update individually, not all values persist
   * as they all try to add to the previous state.
   * This fixes that issue
   */
  const setFieldValues = async (fieldValues) => {
    // console.log("fv", fieldValues, formData);
    // Ignore any values that are already matching what they need to be
    const changedFieldValues = fieldValues;
    // const changedFieldValues = fieldValues.filter(
    //   (fieldValue) => get(formData, fieldValue.name) !== fieldValue.value
    // );

    // console.log("updated fields", changedFieldValues);

    // Mark each of the fields as updated, for saving
    await setUpdatedFields([
      ...updatedFields,
      ...changedFieldValues.map((fieldValue) => fieldValue.name),
    ]);

    // Update the state with multiple changed values
    const newFormState = JSON.parse(JSON.stringify(formData));
    changedFieldValues.forEach((fieldValue) => {
      // console.log(`2. setting ${fieldValue.name} to ${fieldValue.value}`);
      set(newFormState, fieldValue.name, fieldValue.value);
    });

    // Persist the new state
    updateFormData(newFormState);
  };

  /**
   * Updates a single form field value.
   *
   * NOTE: Don't run this multiple times at once. It will
   * lose changes. Instead use setFieldValues for that.
   */
  const setFieldValue = async (fieldName, value) => {
    // console.log("setting field value", fieldName, value);
    // Abort if field already is that value
    if (get(formData, fieldName) === value) {
      // console.log("nothing to update", formData, fieldName, value);
      return;
    }

    await setUpdatedFields([...updatedFields, fieldName]);

    const newFormState = JSON.parse(JSON.stringify(formData));
    // debugger;
    // console.log(`1. setting ${fieldName} to ${value}`);
    // console.log(`form data before`, formData);
    // const newFormState = { ...formData }; // TODO this is only a shallow copy. It's actually mutating the string!!!
    set(newFormState, fieldName, value);
    updateFormData(newFormState);
    // console.log("new form state", JSON.parse(JSON.stringify(newFormState)));
    // console.log("update: ", fieldName, value);
  };

  /**
   * Update the state of the form, but also persist it to local storage
   * @param newFormData
   *
   * TODO don't use this in methods. If not clearing a form, set values one at a time
   */
  const updateFormData = (newFormData) => {
    setFormData(newFormData);

    // Update local storage too
    sessionStorage.setItem("formData", JSON.stringify(newFormData));
  };

  const onNext = () => {
    // TODO validation, disallow changing pages if this page is invalid

    const nextStep = step + 1;
    if (nextStep > totalSteps) {
      console.error(`Cannot reach step ${nextStep}. Form only has ${totalSteps} steps.`);
      return;
    }
    history.push({
      pathname: "/",
      search: `?step=${nextStep}`,
    });
    updateStep(nextStep);
  };

  const onPrevious = () => {
    const previousStep = step - 1;
    if (previousStep < 1) {
      console.error(`Cannot reach step ${previousStep}`);
      return;
    }
    updateStep(previousStep);
  };

  const updateStep = async (newStep, save = true) => {
    // TODO set SAVING state

    // Save the current form data
    if (save) {
      await saveFormData();
    }

    history.push({
      pathname: "/",
      search: `?step=${newStep}`,
    });
  };

  const validateScreen = (screen) => {
    if (!screen) {
      console.log("no screen");
      return false;
    }

    const requiredFields = screen?.requiredFields || [];

    // console.error("required fields: ", requiredFields);
    // console.error("formData fields: ", printObject(formData));

    const missingFields = requiredFields.filter((fieldName) => get(formData, fieldName) === null);

    if (missingFields.length > 0) {
      console.error("missing fields detected: ", missingFields);
      return false;
    }

    return true;
  };

  const submitForm = () => {
    console.log("submitting form", formData);
    setSubmitting(true);
    setErrorMessage("");
  };

  // Submission
  useEffect(() => {
    // Only submit if we have both a signature and have set state to submitting
    if (!formData?.isComplete && isSubmitting && formData?.signature) {
      console.log("submitting for reals");
      (async () => {
        const { data: savedSignup, error: responseError } = await saveSignupApplication({
          ...mapFormDataForAPI(formData),
          completeSubmission: true,
        });

        setSubmitting(false);

        // Show an error if there was one
        if (responseError) {
          setErrorMessage(`Error: ${responseError}`);
          window.scrollTo(0, 0);
        } else {
          // On success

          // Set the contract link
          setFieldValue("contractPdfLink", savedSignup?.contractPdfLink);

          // Redirect to success screen
          setSubmitted(true);
          setSubmitting(false);
          updateStep("success");
        }
      })();
    }
  }, [isSubmitting, formData?.signature]);

  // If we have a contract, set the form as complete
  useEffect(() => {
    if (formData?.contractPdfLink) {
      setFieldValue("isComplete", true);
    }
  }, [formData?.contractPdfLink]);

  useEffect(() => {
    // Scroll to top
    window.scrollTo(0, 0);
  }, [step]);

  const saveAndExit = () => {
    // TODO set SAVING state

    saveFormData().then((response) => {
      // Don't exit if save failed
      if (!response?.error) {
        updateStep("signup", false);
        updateFormData(getDefaultFormValues());
      }
    });
  };

  const logout = () => {
    updateFormData(getDefaultFormValues());
    updateStep("signup");
    signOut();
  };

  /**
   * TODO this should be a part of the form object
   */
  const saveFormData = async () => {
    // No saving if already complete
    if (formData?.isComplete) {
      return {};
    }

    // Don't save if we haven't set basic info yet
    if (!formData?.businessInfo?.tradingName && !formData?.signupId) {
      console.log("Form does not have required fields to be saved");
      setErrorMessage(
        <div>
          <span>Error: Please set the trading name for the form to save</span>
          <Button
            onClick={() => updateStep("restaurant")}
            text="Edit details"
            className="restaurant-selector-create-button"
            type="secondary"
            style={{ color: "#313131" }}
          />
        </div>,
      );
      return {};
    }

    // Escape early if already saving
    if (isSaving.current || saving) {
      console.log("Form is already being saved, exiting");
      return {};
    }

    console.log("saving form data");

    setErrorMessage("");

    setSaving(true);
    isSaving.current = true;

    // Make the save request
    const { data: savedSignup, error: responseError } = await saveSignupApplication(
      mapFormDataForAPI(formData),
    );

    setSaving(false);
    isSaving.current = false;

    if (savedSignup === null) {
      return {
        data: savedSignup,
        error: "Bad value from backend. Please speak to Allen or Cameron",
      };
    }

    // Set the object ID from the response, so we only update from now on
    if (savedSignup?.objectId && !formData.signupId) {
      setFieldValue("signupId", savedSignup?.objectId);
    }

    // Show an error if there was one
    if (responseError) {
      setErrorMessage(`Error: ${responseError}`);

      // scroll to top
      window.scrollTo(0, 0);
    }

    return { data: savedSignup, error: responseError };
  };

  const screens = [
    {
      id: "signup",
      name: "New Signup",
      render: () => (
        <NewVsExisting
          formState={formData}
          setFieldValue={setFieldValue}
          onNext={() => updateStep("restaurant", false)}
          setFormState={updateFormData}
          clearErrors={() => setErrorMessage("")}
        />
      ),
      requiredFields: ["signupId"],
      showFooter: true,
      footerButtonText: "Next: Restaurant details",
      onNext: () => updateStep("restaurant", false),
      showInSelector: true,
    },
    {
      id: "restaurant",
      name: "Restaurant Details",
      render: () => (
        <CreateRestaurant
          formState={formData}
          setFieldValue={setFieldValue}
          setFieldValues={setFieldValues}
          isComplete={formData?.isComplete || false}
        />
      ),
      showFooter: true,
      footerButtonText: "Next: Services",
      footerBackButtonText: "Back",
      onNext: () => updateStep("services"),
      onBack: () => updateStep("signup"),
      showInSelector: true,
    },
    {
      id: "services",
      name: "Services",
      render: () => (
        <Services
          formState={formData}
          setFieldValue={setFieldValue}
          setFieldValues={setFieldValues}
          isComplete={formData?.isComplete || false}
        />
      ),
      showFooter: true,
      footerButtonText: "Next: Pricing",
      footerBackButtonText: "Back",
      onNext: () => updateStep("pricing"),
      onBack: () => updateStep("restaurant"),
      showInSelector: true,
    },
    {
      id: "pricing",
      name: "Pricing",
      render: () => (
        <Pricing
          formState={formData}
          setFieldValue={setFieldValue}
          setFieldValues={setFieldValues}
          isComplete={formData?.isComplete || false}
        />
      ),
      showFooter: true,
      footerButtonText: "Next: Opening hours",
      footerBackButtonText: "Back",
      onNext: () => updateStep("hours"),
      onBack: () => updateStep("services"),
      showInSelector: true,
    },
    {
      id: "hours",
      name: "Hours",
      render: () => (
        <OpeningHoursPage
          formState={formData}
          setFieldValue={setFieldValue}
          setFieldValues={setFieldValues}
          isComplete={formData?.isComplete || false}
        />
      ),
      showFooter: true,
      footerButtonText: "Next: Communication",
      footerBackButtonText: "Back",
      onNext: () => updateStep("communication"),
      onBack: () => updateStep("pricing"),
      showInSelector: true,
    },
    {
      id: "communication",
      name: "Communication",
      render: () => (
        <CommunicationsPage
          formState={formData}
          setFieldValue={setFieldValue}
          setFieldValues={setFieldValues}
          isComplete={formData?.isComplete || false}
        />
      ),
      showFooter: true,
      footerButtonText: "Next: Operations",
      footerBackButtonText: "Back",
      onNext: () => updateStep("operations"),
      onBack: () => updateStep("hours"),
      showInSelector: true,
    },
    {
      id: "operations",
      name: "Operations",
      render: () => (
        <OperationalDetailsPage
          formState={formData}
          setFieldValue={setFieldValue}
          isComplete={formData?.isComplete || false}
        />
      ),
      showFooter: true,
      footerButtonText: "Next: Deals",
      footerBackButtonText: "Back",
      onNext: () => updateStep("deals"),
      onBack: () => updateStep("communication"),
      showInSelector: true,
    },
    {
      id: "deals",
      name: "Deals",
      render: () => (
        <DealsPage
          formState={formData}
          setFieldValue={setFieldValue}
          isComplete={formData?.isComplete || false}
        />
      ),
      showFooter: true,
      footerButtonText: `Next: ${useWhiteLabel ? "White label" : "Settlement details"}`,
      footerBackButtonText: "Back",
      onNext: () => (useWhiteLabel ? updateStep("order_direct") : updateStep("payment")),
      onBack: () => updateStep("operations"),
      showInSelector: true,
    },
    {
      id: "order_direct",
      name: "Order Direct",
      render: () => (
        <WhiteLabelSetupPage
          formState={formData}
          setFieldValue={setFieldValue}
          isComplete={formData?.isComplete}
        />
      ),
      showFooter: true,
      footerButtonText: "Next: Settlement details",
      footerBackButtonText: "Back",
      onNext: () => updateStep("payment"),
      onBack: () => updateStep("deals"),
      showInSelector: useWhiteLabel,
    },
    // {
    //   id: "marketing",
    //   name: "Marketing",
    //   render: () => (
    //     <MarketingPage
    //       formState={formData}
    //       setFieldValue={setFieldValue}
    //       setFormState={updateFormData}
    //     />
    //   ),
    //   showFooter: true,
    //   footerButtonText: "Next: Stripe",
    //   footerBackButtonText: "Back",
    //   onNext: () => updateStep("payment"),
    //   onBack: () =>
    //     useWhiteLabel ? updateStep("order_direct") : updateStep("deals"),
    //   showInSelector: true,
    // },
    {
      id: "payment",
      name: "Settlement details",
      render: () => (
        <PaymentDetailsPage
          formState={formData}
          setFieldValue={setFieldValue}
          setFieldValues={setFieldValues}
          saveFormData={saveFormData}
          isComplete={formData?.isComplete}
        />
      ),
      showFooter: true,
      footerButtonText: "Next: Review and finalise",
      footerBackButtonText: "Back",
      onNext: () => updateStep("review"),
      onBack: () => (useWhiteLabel ? updateStep("order_direct") : updateStep("deals")),
      showInSelector: true,
    },
    {
      id: "review",
      name: "Review",
      render: () => (
        <Finalise
          formState={formData}
          setFieldValue={setFieldValue}
          submitForm={submitForm}
          setPageId={updateStep}
          isSubmitting={isSubmitting}
          isComplete={formData?.isComplete || false}
        />
      ),
      footerBackButtonText: "Back",
      onBack: () => updateStep("payment"),
      showFooter: true,
      showInSelector: true,
    },
    {
      id: "success",
      name: "Success",
      render: () => (
        <Success
          formState={formData}
          setFieldValue={setFieldValue}
          resetForm={() => {
            updateStep("signup");
            updateFormData(getDefaultFormValues());
          }}
        />
      ),
      showInSelector: false,
    },
  ];
  const totalSteps = screens.length;

  const getScreenById = (screenId) => screens.find((screen) => screen.id === screenId);

  const currentScreen = getScreenById(step);

  // Check whether the screen is valid, for the footer
  useEffect(() => {
    setIsScreenValid(validateScreen(currentScreen));
  }, [currentScreen, formData]);

  // console.log("screen is valid:", isScreenValid);

  // If we tried to access an invalid step, return to first page of form
  if (currentScreen === null || currentScreen === undefined) {
    updateStep("signup");
    return <></>;
  }

  return (
    <Box className="signup-form">
      <SignupHeader
        step={step}
        screens={screens}
        setStep={updateStep}
        saveAndExit={saveAndExit}
        logout={logout}
        disableSaveButton={currentScreen.id === "success" || !formData?.businessInfo?.tradingName}
        isComplete={formData?.isComplete || false}
      />
      {saving && (
        <Box
          style={{
            width: "100%",
            height: "50px",
            backgroundColor: "#d8ffba",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            zIndex: 10,
            top: "100px",
          }}
        >
          Saving changes...
        </Box>
      )}
      <Box className="container form-container">
        {errorMessage && (
          <Box className="error-box">
            <Box style={{ display: "inline-flex", columnGap: "5px" }}>
              <CriticalWarningSVG
                style={{
                  // position: "absolute",
                  // marginLeft: "3px",
                  marginTop: "8px",
                  height: "24px",
                }}
              />
              <Box style={{ marginBottom: "15px" }} className="error-text">
                {errorMessage}
              </Box>
            </Box>
          </Box>
        )}
        {currentScreen.render()}
      </Box>
      {currentScreen?.showFooter && (
        <SignupFooter
          isValid={isScreenValid}
          onNext={currentScreen?.onNext}
          onPrevious={currentScreen?.onBack}
          nextButtonText={currentScreen?.footerButtonText}
          backButtonText={currentScreen?.footerBackButtonText}
        />
      )}
    </Box>
  );
};
