import { makeStyles } from "@mui/styles";
import { COLORS } from "../../../theme";

const useStyles = makeStyles(
  () => ({
    items: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      gap: "22px",
    },
    itemRow: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      // gap: "22px",
    },
    itemLabel: {
      color: COLORS.SMOKE,
      width: "290px",
      flexShrink: 0,
    },
  }),
  { name: "DetailsGroup" },
);

export default useStyles;
