import { Button } from "@eatclub-apps/ec-component-library";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Box } from "@mui/material";
import { useHistory } from "react-router-dom";
import get from "lodash.get";
import { formStateType } from "../../../types";
import { getDefaultFormValues } from "../../../data/DefaultFormValues";
import {
  fetchRestaurantsByName,
  getPartnerSignupForId,
  SEARCH_RESULT_TYPE,
} from "../../../graphql/api-client";
import { isSpecialBuddy, trim } from "../../../helpers/Helpers";
import { RestaurantSelector } from "../../RestaurantSelector/RestaurantSelector";

export const NewVsExisting = ({ formState, setFormState, setFieldValue, onNext, clearErrors }) => {
  const { newSignup } = formState;
  const history = useHistory();

  const [fetchedRestaurants, setFetchedRestaurants] = useState([]);
  const [loadingRestaurants, setLoadingRestaurants] = useState(false);
  const [restaurantName, setRestaurantName] = useState(null);
  const [error, setError] = useState("");

  const resetForm = (tradingName = null) => {
    setFormState(getDefaultFormValues({ tradingName }));
    clearErrors();
  };

  // TODO just go to next form screen if create partner button clicked
  const showCreatePartner = () => {
    resetForm(trim(restaurantName));
    onNext();
  };

  const setPartner = (partner) => {
    // Check we actually can set it to a partner
    if (partner === null) {
      resetForm();
      return;
    }

    console.log("selecting partner:", partner);

    // If no signup exists, create a new one
    if (
      partner.type === SEARCH_RESULT_TYPE.ECVENUE ||
      partner.type === SEARCH_RESULT_TYPE.HUBSPOT
    ) {
      const newState = getDefaultFormValues();
      // TODO pull more info from somewhere to prefill
      newState.businessInfo = {
        id: partner.objectId,
        legalName: partner.title,
        tradingName: partner.title,
      };
      newState.newSignup = false;
      setFormState(newState);
    } else {
      (async () => {
        let formValues = getDefaultFormValues();
        const { data: partnerSignup, error: responseError } = await getPartnerSignupForId(
          partner.objectId,
        );

        console.log("fetched signup", partnerSignup);

        if (responseError) {
          setError(responseError);
          return;
        }

        formValues = { ...formValues, ...partnerSignup };

        // Ensure we correctly tell whether it's a new signup or not
        if (partnerSignup?.businessInfo?.id) {
          formValues.newSignup = false;
        }

        // Ensure we correctly tell whether it's a new signup or not
        if (partner.type === SEARCH_RESULT_TYPE.COMPLETED) {
          formValues.newSignup = false;
          formValues.isComplete = true;
        }

        // Fetch partner from backend
        console.log("signup", formValues);
        setFormState(formValues);
      })();
    }
  };

  //

  // TODO disable footer button unless a restaurant is selected

  // TODO take a prop like "onFieldUpdate" that passes the field name and value to the parent form

  // TODO if partner ID set, don't show create partner button

  console.log("fetched", fetchedRestaurants);
  const selectedRestaurant = fetchedRestaurants.find(
    (partner) => partner.id === get(formState, "businessInfo.id"),
  );

  const onType = (value) => {
    console.log("Searching", value);

    setLoadingRestaurants(true);
    setRestaurantName(value);

    (async () => {
      const { data: restaurants, error: responseError } = await fetchRestaurantsByName(value);

      if (responseError) {
        setLoadingRestaurants(false);
        setError(responseError);
        return;
      }

      console.log("restaurants", restaurants);
      setFetchedRestaurants(restaurants);
      setLoadingRestaurants(false);
    })();
  };

  return (
    <>
      <Box style={{ marginBottom: "20px" }}>
        <h1>Partner sign up</h1>
      </Box>
      <Box style={{ marginBottom: "55px" }}>
        <p className="paragraph-large">
          Get a new partner profile set up and ready to start taking orders.
        </p>
      </Box>

      {error && (
        <Box style={{ marginBottom: "10px" }} className="error-text-medium">
          {error}
        </Box>
      )}

      {isSpecialBuddy() && (
        <Box style={{ marginBottom: "55px" }}>
          <Button
            type="text"
            className="paragraph-medium"
            onClick={() => history.push("/cc_override")}
          >
            Click here to override credit card details for a venue
          </Button>
        </Box>
      )}

      <RestaurantSelector
        items={fetchedRestaurants}
        label="Partner name or ID"
        placeholder="Search existing partner name or ID"
        style={{ width: "100%", maxWidth: "480px", marginBottom: "32px" }}
        showCreatePartner={showCreatePartner}
        searchRestaurants={onType}
        onSelect={setPartner}
        defaultValue={selectedRestaurant}
        loading={loadingRestaurants}
      />
      <Box>
        <Button text="Create new partner" type="secondary" onClick={showCreatePartner} />
      </Box>
    </>
  );
};

NewVsExisting.propTypes = {
  formState: formStateType.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};
