import { Box, Divider } from "@mui/material";
import { Button, TextField } from "@eatclub-apps/ec-component-library";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { formStateType } from "../../../types";
import { TYPES } from "../../../helpers/InputTypes";
import { trim, unique } from "../../../helpers/Helpers";
import { EmailInputList } from "../../EmailInputList/EmailInputList";

export const CommunicationsPage = ({
  formState: {
    communication: { orders, oppos, emailAddresses, marketing, invoices },
  },
  setFieldValue,
  setFieldValues,
  isComplete,
}) => {
  // TODO make the email input part its own component
  const [emailInput, setEmailInput] = useState("");

  const [marketingEmails, setMarketingEmails] = useState(marketing.emailAddresses || []);
  const [orderEmails, setOrderEmails] = useState(orders.emailAddresses);
  const [invoiceEmails, setInvoiceEmails] = useState(invoices.emailAddresses);
  const [oppoEmails, setOppoEmails] = useState(oppos.emailAddresses);

  const addEmailToAll = () => {
    // TODO email address validation
    if (!emailInput) {
      return;
    }

    const newMarketingEmails = unique([
      ...marketing.emailAddresses.filter((email) => email !== ""),
      trim(emailInput),
    ]);
    setMarketingEmails(newMarketingEmails);

    const newOrderEmails = unique([
      ...orders.emailAddresses.filter((email) => email !== ""),
      trim(emailInput),
    ]);
    setOrderEmails(newOrderEmails);

    const newInvoiceEmails = unique([
      ...invoices.emailAddresses.filter((email) => email !== ""),
      trim(emailInput),
    ]);
    setInvoiceEmails(newInvoiceEmails);

    const newOppoEmails = unique([
      ...oppos.emailAddresses.filter((email) => email !== ""),
      trim(emailInput),
    ]);
    setOppoEmails(newOppoEmails);

    setFieldValues([
      { name: "communication.marketing.emailAddresses", value: newMarketingEmails },
      { name: "communication.orders.emailAddresses", value: newOrderEmails },
      { name: "communication.invoices.emailAddresses", value: newInvoiceEmails },
      { name: "communication.oppos.emailAddresses", value: newOppoEmails },
      { name: "communication.marketing.isUsed", value: true },
      { name: "communication.orders.isUsed", value: true },
      { name: "communication.invoices.isUsed", value: true },
      { name: "communication.oppos.isUsed", value: true },
    ]);

    // Clear the existing input
    setEmailInput("");
  };

  const removeEmail = (index) => {
    const updatedEmails = [...emailAddresses].filter((email, i) => i !== index);
    setFieldValue("communication.emailAddresses", updatedEmails);
  };

  // setFieldValue("communication.marketing.emailAddresses", [""]);

  return (
    <>
      <Box style={{ marginBottom: "20px" }}>
        <h1>Communication</h1>
      </Box>
      <Box style={{ marginBottom: "55px" }}>
        <p className="paragraph-large">Enter an email to receive important emails from EatClub</p>
      </Box>
      <Box className="gray-box" style={{ marginBottom: "40px", maxWidth: "540px" }}>
        <Box style={{ marginBottom: "20px" }}>
          <h4>Add an email to all channels</h4>
        </Box>
        <Box className="flex-space-medium" style={{ display: "flex", marginBottom: "10px" }}>
          <TextField
            style={{ flexGrow: 1 }}
            label="Email"
            type={TYPES.EMAIL}
            value={emailInput}
            onChange={setEmailInput}
            onEnter={addEmailToAll}
            disabled={isComplete}
          />
          <Button
            style={{ marginTop: "40px" }}
            text="Add email"
            type="text"
            className="brand-link-color"
            onClick={addEmailToAll}
            disabled={isComplete}
          />
        </Box>
      </Box>

      <EmailInputList
        title="Invoices and payment summaries*"
        emailAddresses={invoiceEmails}
        isUsed="true"
        setEmailAddresses={(value, addressIndex = null) => {
          if (addressIndex !== null) {
            const newInvoiceEmails = [...invoiceEmails];
            newInvoiceEmails[addressIndex] = value;
            setInvoiceEmails(newInvoiceEmails);
          } else {
            setInvoiceEmails(value);
          }
        }}
        saveChanges={(changes) => setFieldValue("communication.invoices.emailAddresses", changes)}
        setIsUsed={(value) => setFieldValue("communication.invoices.isUsed", value)}
        disabled={isComplete}
      />

      <Divider style={{ maxWidth: "600px", marginTop: "20px", marginBottom: "20px" }} />

      <EmailInputList
        title="Marketing"
        // emailAddresses={marketing.emailAddresses}
        emailAddresses={marketingEmails}
        isUsed={marketing.isUsed}
        setEmailAddresses={(value, addressIndex = null) => {
          if (addressIndex !== null) {
            const newMarketingEmails = [...marketingEmails];
            newMarketingEmails[addressIndex] = value;
            setMarketingEmails(newMarketingEmails);
          } else {
            setMarketingEmails(value);
          }
        }}
        saveChanges={(changes) => setFieldValue("communication.marketing.emailAddresses", changes)}
        setIsUsed={(value) => setFieldValue("communication.marketing.isUsed", value)}
        disabled={isComplete}
      />

      <Divider style={{ maxWidth: "600px", marginTop: "20px", marginBottom: "20px" }} />

      <EmailInputList
        title="Order and booking confirmations"
        emailAddresses={orderEmails}
        isUsed={orders?.isUsed}
        setEmailAddresses={(value, addressIndex = null) => {
          if (addressIndex !== null) {
            const newOrderEmails = [...orderEmails];
            newOrderEmails[addressIndex] = value;
            setOrderEmails(newOrderEmails);
          } else {
            setOrderEmails(value);
          }
        }}
        saveChanges={(changes) => setFieldValue("communication.orders.emailAddresses", changes)}
        setIsUsed={(value) => setFieldValue("communication.orders.isUsed", value)}
        disabled={isComplete}
      />

      <Divider style={{ maxWidth: "600px", marginTop: "20px", marginBottom: "20px" }} />

      <EmailInputList
        title="Daily opportunities"
        emailAddresses={oppoEmails}
        isUsed={oppos?.isUsed}
        setEmailAddresses={(value, addressIndex = null) => {
          if (addressIndex !== null) {
            const newOppoEmails = [...oppoEmails];
            newOppoEmails[addressIndex] = value;
            setOppoEmails(newOppoEmails);
          } else {
            setOppoEmails(value);
          }
        }}
        saveChanges={(changes) => setFieldValue("communication.oppos.emailAddresses", changes)}
        setIsUsed={(value) => setFieldValue("communication.oppos.isUsed", value)}
        disabled={isComplete}
      />
    </>
  );
};

CommunicationsPage.propTypes = {
  formState: formStateType.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};
