import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SegmentedControl, TextField, Button } from "@eatclub-apps/ec-component-library";
import PropTypes from "prop-types";
import { onOff } from "../../data/ValueLists";
import { TYPES } from "../../helpers/InputTypes";
import { isValidEmail, trim, isEmpty } from "../../helpers/Helpers";

export const EmailInputList = ({
  title,
  isUsed,
  emailAddresses,
  setIsUsed,
  setEmailAddresses,
  disabled,
  saveChanges,
  required,
}) => {
  useEffect(() => {
    if (emailAddresses.length === 0) {
      setEmailAddresses([""]);
    }
  }, [isUsed]);

  const emailsValid = emailAddresses.map((email, index) => !isEmpty(email) && isValidEmail(email));

  const [focusedTextField, setFocusedTextField] = useState(null);

  return (
    <Box style={{ maxWidth: "600px" }} className="paragraph-small">
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        <h4 style={{ paddingTop: "10px" }}>{title}</h4>
        <SegmentedControl
          items={onOff()}
          value={isUsed && emailAddresses?.length !== 0}
          disabled={disabled}
          onSelect={(value) => {
            setIsUsed(value);
            if (!emailAddresses?.length) {
              setEmailAddresses([""]);
            }
          }}
        />
      </Box>

      {isUsed && emailAddresses?.length !== 0 && (
        <Box style={{ marginTop: "20px" }}>
          {emailAddresses?.length &&
            emailAddresses.map((email, index) => (
              <Box style={{ marginTop: "10px" }}>
                <Box style={{ display: "inline-flex", columnGap: "20px" }}>
                  <Box style={{ width: "100%", maxWidth: "400px" }}>
                    <TextField
                      style={{ width: "400px" }}
                      type={TYPES.EMAIL}
                      value={email}
                      onChange={(value) => setEmailAddresses(trim(value), index)}
                      disabled={disabled}
                      onBlur={() => {
                        setFocusedTextField(null);
                        saveChanges(emailAddresses);
                      }}
                      onFocus={() => setFocusedTextField(index)}
                      error={
                        focusedTextField !== index &&
                        !isEmpty(email) &&
                        !emailsValid?.[index] && <Box>Email address is not valid</Box>
                      }
                    />
                  </Box>
                  <Button
                    type="text"
                    className="brand-link-color"
                    text="Remove"
                    style={{ marginTop: "10px" }}
                    disabled={disabled}
                    onClick={() => {
                      const newEmailAddresses = emailAddresses.filter(
                        (emailAddress, filterIndex) => filterIndex !== index,
                      );

                      setEmailAddresses(newEmailAddresses);

                      // Set to off if no emails
                      if (newEmailAddresses.length === 0) {
                        setIsUsed(false);
                      }

                      saveChanges(newEmailAddresses);
                    }}
                  />
                </Box>
              </Box>
            ))}
          <Box style={{ marginTop: "20px" }}>
            <Button
              type="text"
              className="brand-link-color"
              text="Add another email"
              onClick={() => setEmailAddresses([...emailAddresses, ""])}
              disabled={disabled}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

EmailInputList.defaultProps = {
  saveChanges: () => {},
  required: false,
};

EmailInputList.propTypes = {
  title: PropTypes.string.isRequired,
  isUsed: PropTypes.bool.isRequired,
  emailAddresses: PropTypes.arrayOf(PropTypes.string).isRequired,
  setIsUsed: PropTypes.func.isRequired,
  setEmailAddresses: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  saveChanges: PropTypes.func,
  required: PropTypes.bool,
};
