import { Button, Pill, TextField } from "@eatclub-apps/ec-component-library";
import { isValidPhoneNumber } from "libphonenumber-js";
import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import get from "lodash.get";
import {
  getLabelForValue,
  setupPaymentFrequencies,
  takeawayPricingTypes,
  membershipPaymentFrequencies,
  weekdays,
  yesNo,
  preparationTimes,
  dineInPricingTypes,
  eatclubBookingsPlans,
} from "../../../data/ValueLists";
import { formStateType } from "../../../types";
import { formFields } from "../../../data/FormFields";
import { getPartnerForEmail, checkSlugExists } from "../../../graphql/api-client";
import CriticalWarningSVG from "../../../assets/critical_warning.svg";
import { TYPES } from "../../../helpers/InputTypes";
import {
  convertDateFormat,
  formatCurrency,
  formatTimeFromInt,
  isECPayRegion,
  isEmpty,
  isURL,
  isValidEmail,
  parseURL,
  trim,
  validateABN,
} from "../../../helpers/Helpers";
import { LabelledData } from "../../LabelledData/LabelledData";
import { DealGrid } from "../../DealList/DealGrid";
import { CompletedItem } from "../../CompletedItem/CompletedItem";
import { getBookingsFee } from "./Pricing";

export const Finalise = ({
  formState,
  setFieldValue,
  submitForm,
  setPageId,
  isSubmitting,
  isComplete,
}) => {
  const {
    newSignup,
    businessInfo,
    accountHolder,
    services,
    fees,
    openingHours,
    operations,
    deals,
    orderDirect,
    paymentDetails,
    username,
    password,
  } = formState;

  const [validationErrors, setValidationErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [inputUsername, setUsername] = useState(formState.username);
  const [inputPassword, setPassword] = useState(formState.password);

  const useWhiteLabel = () =>
    services?.whiteLabel?.tableOrdering ||
    services?.whiteLabel?.takeaway ||
    services?.whiteLabel?.delivery;

  const bookingsOnly =
    formState?.services?.bookings?.enabled &&
    formState?.services?.bookings?.plan === "bookingsOnly";

  const bankDetailsValid = () =>
    !!(paymentDetails.bankAccountName && paymentDetails.bsb && paymentDetails.bankAccountNumber);

  const stripeAccountConnected = () => {
    // If cc override, must provide bank details but not necessarily card details
    if (formState.ccOverride) {
      if (!bankDetailsValid()) {
        return false;
      }

      return true;
    }

    // If payment details are not set, it's not valid
    if (!paymentDetails.creditCardCapture && !paymentDetails.directDebitCapture) {
      return false;
    }

    if (paymentDetails.creditCardCapture && !paymentDetails.creditCardStripeId) {
      return false;
    }

    // if (paymentDetails.directDebitCapture && !paymentDetails.directDebitStripeId) {
    //   return false;
    // }

    return true;

    // TODO validate specific fields for business type
  };

  const handleSubmit = () => {
    setIsLoading(true);
    let errors = validateForm();

    // Extra validation - signature and username/password
    // if (signatureCanvasRef.current.isEmpty()) {
    //   errors = [
    //     ...errors,
    //     <Box className="paragraph-regular" style={{ display: "inline-flex", columnGap: "10px" }}>
    //       <Box>Please add a signature</Box>
    //     </Box>,
    //   ];
    // }

    if (!username || !password) {
      errors = [
        ...errors,
        <Box className="paragraph-regular" style={{ display: "inline-flex", columnGap: "10px" }}>
          <Box>Please enter a username and a password</Box>
        </Box>,
      ];
    }

    if (password?.length < 8) {
      errors = [
        ...errors,
        <Box className="paragraph-regular" style={{ display: "inline-flex", columnGap: "10px" }}>
          <Box>Your password must be at least 8 characters</Box>
        </Box>,
      ];
    }

    (async () => {
      // Validate email address is unique
      if (newSignup) {
        const existingPartner = await getPartnerForEmail({ email: username });
        if (!existingPartner?.error && existingPartner?.data?.objectId !== businessInfo?.id) {
          errors = [
            ...errors,
            <Box
              className="paragraph-regular"
              style={{ display: "inline-flex", columnGap: "10px" }}
            >
              <Box>
                The account email address is already in use. Please use a different email address.
              </Box>
            </Box>,
          ];
        }
      }

      // Validate bookings slug
      if (services?.bookings?.enabled) {
        const existingSlug = await checkSlugExists({ slug: services?.bookings?.slug });

        if (existingSlug) {
          errors = [
            ...errors,
            <Box
              className="paragraph-regular"
              style={{ display: "inline-flex", columnGap: "10px" }}
            >
              <Box>
                The bookings URL &apos;{services?.bookings?.slug}&apos; is already in use. Please
                try a different URL
              </Box>
              <Button
                type="text"
                className="brand-link-color"
                onClick={() => setPageId("services")}
                text="Edit"
                style={{ button: { textDecoration: "underline" } }}
              />
            </Box>,
          ];
        }
      }

      setValidationErrors(errors);

      if (errors.length === 0) {
        // Upload image
        // const imageData = decodeBase64Image(signatureCanvasRef.current.toDataURL("image/png"));
        // const image = await uploadImage(formState.signupId, imageData, "signature", "signature");
        // await setFieldValue("signature", image?.data?.link);

        console.log("form data after signature upload", JSON.parse(JSON.stringify(formState)));

        await submitForm();
        setIsLoading(false); // Not loading, but could be submitting
      } else {
        setIsLoading(false);
        window.scrollTo(0, 0);
      }
    })();
  };

  /**
   * Validate that the form has all of its fields filled out and valid.
   * We want as few fields required as possible
   */
  const validateForm = () => {
    console.log("validating form");
    // TODO validate each field on blur?
    const validationRules = formFields();

    let errors = [];
    validationRules.forEach((field) => {
      const value = get(formState, field.fieldName);

      // Required field validation
      if (field.required) {
        let emptyValue = value === "" || value === null;

        // Validate communications emails
        if (field.fieldName.includes("communication.")) {
          emptyValue =
            !value?.isUsed || isEmpty(value?.emailAddresses) || value?.emailAddresses?.length === 0;
        }

        if (emptyValue) {
          errors = [
            ...errors,
            <Box
              className="paragraph-regular"
              style={{ display: "inline-flex", columnGap: "10px" }}
            >
              <Box>
                Please enter the <span style={{ color: "#000000" }}>{field?.description}</span>
              </Box>
              <Button
                type="text"
                className="brand-link-color"
                onClick={() => setPageId(field.pageId)}
                text="Edit details"
                style={{ button: { textDecoration: "underline" } }}
              />
            </Box>,
          ];
        }
      } else if (field.validationFunction === false) {
        // If failed custom validation
        errors = [...errors, <Box>{field.validationErrorMessage}</Box>];
      }
    });

    // Validate phone numbers
    if (businessInfo?.phoneNumber && !isValidPhoneNumber(businessInfo.phoneNumber, "AU")) {
      errors = [
        ...errors,
        <Box className="paragraph-regular" style={{ display: "inline-flex", columnGap: "10px" }}>
          <Box>Business phone number is not a valid Australian phone number</Box>
          <Button
            type="text"
            className="brand-link-color"
            onClick={() => setPageId("restaurant")}
            text="Edit details"
            style={{ button: { textDecoration: "underline" } }}
          />
        </Box>,
      ];
    }

    if (accountHolder?.phoneNumber && !isValidPhoneNumber(accountHolder.phoneNumber, "AU")) {
      errors = [
        ...errors,
        <Box className="paragraph-regular" style={{ display: "inline-flex", columnGap: "10px" }}>
          <Box>Signing authority phone number is not a valid Australian phone number</Box>
          <Button
            type="text"
            className="brand-link-color"
            onClick={() => setPageId("restaurant")}
            text="Edit details"
            style={{ button: { textDecoration: "underline" } }}
          />
        </Box>,
      ];
    }

    // At least one service must be selected
    const requiredServices = [
      services.takeaway.takeawayVouchers,
      services.takeaway.takeawayIAO,
      services.dineIn.dineInVouchers,
      services.dineIn.tableOrders,
      services.whiteLabel.tableOrdering,
      services.whiteLabel.takeaway,
      services.whiteLabel.delivery,
      services?.bookings?.enabled && services?.bookings?.plan === "bookingsOnly",
    ];
    if (requiredServices.filter((service) => service).length === 0) {
      errors = [
        ...errors,
        <Box className="paragraph-regular" style={{ display: "inline-flex", columnGap: "10px" }}>
          <Box>
            {services?.bookings?.enabled
              ? "If using bookings but not EatClub, set the plan to bookings only."
              : "At least one service must be selected"}
          </Box>
          <Button
            type="text"
            className="brand-link-color"
            onClick={() => setPageId("services")}
            text="Edit details"
            style={{ button: { textDecoration: "underline" } }}
          />
        </Box>,
      ];
    }

    if (services?.bookings?.enabled && isEmpty(services?.bookings?.email)) {
      errors = [
        ...errors,
        <Box className="paragraph-regular" style={{ display: "inline-flex", columnGap: "10px" }}>
          <Box>When using EC Bookings you must set the operations email</Box>
          <Button
            type="text"
            className="brand-link-color"
            onClick={() => setPageId("services")}
            text="Edit details"
            style={{ button: { textDecoration: "underline" } }}
          />
        </Box>,
      ];
    }

    // Check opening hours all end before they start, and that split shifts don't overlap
    weekdays().forEach((weekday) => {
      // ignore closed days
      if (!openingHours[weekday.value].isOpen) {
        return;
      }

      openingHours[weekday.value].shifts.forEach((shift) => {
        if (shift.end < shift.start) {
          errors = [
            ...errors,
            <Box
              className="paragraph-regular"
              style={{ display: "inline-flex", columnGap: "10px" }}
            >
              <Box>{weekday.label} has a shift with invalid hours</Box>
              <Button
                type="text"
                className="brand-link-color"
                onClick={() => setPageId("hours")}
                text="Edit details"
                style={{ button: { textDecoration: "underline" } }}
              />
            </Box>,
          ];
        }
      });

      // If shifts overlap
      if (openingHours[weekday.value].shifts.length > 1) {
        // If the end time is after the start time (exclude midnight)
        if (
          openingHours[weekday.value].shifts[0].start < openingHours[weekday.value].shifts[1].end &&
          openingHours[weekday.value].shifts[0].end > openingHours[weekday.value].shifts[1].start
        ) {
          errors = [
            ...errors,
            <Box
              className="paragraph-regular"
              style={{ display: "inline-flex", columnGap: "10px" }}
            >
              <Box>The shifts for {weekday.label} are overlapping</Box>
              <Button
                type="text"
                className="brand-link-color"
                onClick={() => setPageId("hours")}
                text="Edit details"
                style={{ button: { textDecoration: "underline" } }}
              />
            </Box>,
          ];
        }
      }
    });

    // Validate bank transfer details
    if (!bankDetailsValid()) {
      errors = [
        ...errors,
        <Box className="paragraph-regular" style={{ display: "inline-flex", columnGap: "10px" }}>
          <Box>Bank transfer details are not valid</Box>
          <Button
            type="text"
            className="brand-link-color"
            onClick={() => setPageId("payment")}
            text="Edit details"
            style={{ button: { textDecoration: "underline" } }}
          />
        </Box>,
      ];
    }

    // Credentials must be set, probably
    if (!stripeAccountConnected()) {
      errors = [
        ...errors,
        <Box className="paragraph-regular" style={{ display: "inline-flex", columnGap: "10px" }}>
          <Box>Please add a credit card for collection</Box>
          <Button
            type="text"
            className="brand-link-color"
            onClick={() => setPageId("payment")}
            text="Edit details"
            style={{ button: { textDecoration: "underline" } }}
          />
        </Box>,
      ];
    }

    // Validate DOB
    if (
      convertDateFormat(formState?.accountHolder.dateOfBirth, "DD/MM/YYYY", "YYYY-MM-DD") === null
    ) {
      errors = [
        ...errors,
        <Box className="paragraph-regular" style={{ display: "inline-flex", columnGap: "10px" }}>
          <Box>The date of birth is invalid. Please make sure it is dd/mm/yyyy</Box>
          <Button
            type="text"
            className="brand-link-color"
            onClick={() => setPageId("restaurant")}
            text="Edit details"
            style={{ button: { textDecoration: "underline" } }}
          />
        </Box>,
      ];
    }

    // Validate business website
    if (!isURL(parseURL(formState?.businessInfo?.website))) {
      errors = [
        ...errors,
        <Box className="paragraph-regular" style={{ display: "inline-flex", columnGap: "10px" }}>
          <Box>The business website is invalid, please check it and try again</Box>
          <Button
            type="text"
            className="brand-link-color"
            onClick={() => setPageId("restaurant")}
            text="Edit details"
            style={{ button: { textDecoration: "underline" } }}
          />
        </Box>,
      ];
    }

    // Validate ACN
    if (isEmpty(businessInfo?.acn) && businessInfo?.businessType === "company") {
      errors = [
        ...errors,
        <Box className="paragraph-regular" style={{ display: "inline-flex", columnGap: "10px" }}>
          <Box>
            When business type is Company, an ACN must be provided. Please either provide an ACN or
            set the business type to Individual
          </Box>
          <Button
            type="text"
            className="brand-link-color"
            onClick={() => setPageId("restaurant")}
            style={{ button: { textDecoration: "underline" } }}
            text="Edit ACN"
          />
          <Button
            type="text"
            className="brand-link-color"
            onClick={() => setPageId("payment")}
            text="Edit business type"
            style={{ button: { textDecoration: "underline" } }}
          />
        </Box>,
      ];
    }

    // Validate ABN
    if (!isEmpty(businessInfo?.abn) && !validateABN(businessInfo?.abn)) {
      errors = [
        ...errors,
        <Box className="paragraph-regular" style={{ display: "inline-flex", columnGap: "10px" }}>
          <Box>ABN is invalid, please check again</Box>
          <Button
            type="text"
            className="brand-link-color"
            onClick={() => setPageId("restaurant")}
            text="Edit ABN"
            style={{ button: { textDecoration: "underline" } }}
          />
        </Box>,
      ];
    }

    // Validate email addresses
    let invalidEmails = [];

    // Username
    if (!isEmpty(inputUsername) && !isValidEmail(inputUsername)) {
      invalidEmails = [
        ...invalidEmails,
        { message: "Login username is not a valid email address" },
      ];
    }

    // Account holder email
    if (!isEmpty(inputUsername) && !isValidEmail(inputUsername)) {
      invalidEmails = [
        ...invalidEmails,
        { message: "Account holder email is not a valid email address", page: "restaurant" },
      ];
    }

    // Bookings email
    if (
      formState.services.bookings.enabled &&
      !isEmpty(formState.services.bookings.email) &&
      !isValidEmail(formState.services.bookings.email)
    ) {
      invalidEmails = [
        ...invalidEmails,
        { message: "Bookings email is not a valid email address", page: "services" },
      ];
    }

    // Communication emails
    if (formState.communication.marketing.isUsed) {
      const hasInvalidEmail = formState.communication.marketing.emailAddresses.find(
        (emailAddress) => !isEmpty(emailAddress) && !isValidEmail(emailAddress),
      );

      if (hasInvalidEmail) {
        invalidEmails = [
          ...invalidEmails,
          { message: "There is an invalid marketing email", page: "communication" },
        ];
      }
    }

    if (formState.communication.orders.isUsed) {
      const hasInvalidEmail = formState.communication.orders.emailAddresses.find(
        (emailAddress) => !isEmpty(emailAddress) && !isValidEmail(emailAddress),
      );

      if (hasInvalidEmail) {
        invalidEmails = [
          ...invalidEmails,
          { message: "There is an invalid orders email", page: "communication" },
        ];
      }
    }

    if (formState.communication.invoices.isUsed) {
      const hasInvalidEmail = formState.communication.invoices.emailAddresses.find(
        (emailAddress) => !isEmpty(emailAddress) && !isValidEmail(emailAddress),
      );

      if (hasInvalidEmail) {
        invalidEmails = [
          ...invalidEmails,
          { message: "There is an invalid invoices email", page: "communication" },
        ];
      }
    }

    if (formState.communication.oppos.isUsed) {
      const hasInvalidEmail = formState.communication.oppos.emailAddresses.find(
        (emailAddress) => !isEmpty(emailAddress) && !isValidEmail(emailAddress),
      );

      if (hasInvalidEmail) {
        invalidEmails = [
          ...invalidEmails,
          { message: "There is an invalid opportunities email", page: "communication" },
        ];
      }
    }

    if (!isEmpty(invalidEmails)) {
      invalidEmails.forEach((invalidEmail) => {
        errors = [
          ...errors,
          <Box className="paragraph-regular" style={{ display: "inline-flex", columnGap: "10px" }}>
            <Box>{invalidEmail?.message}</Box>
            {!isEmpty(invalidEmail?.page) && (
              <Button
                type="text"
                className="brand-link-color"
                onClick={() => setPageId(invalidEmail?.page)}
                text="Edit"
                style={{ button: { textDecoration: "underline" } }}
              />
            )}
          </Box>,
        ];
      });
    }

    setValidationErrors(errors);
    return errors;
  };

  const [validatingEmail, setValidatingEmail] = useState(false);
  const [emailValidationMessage, setEmailValidationMessage] = useState("");
  const validateEmailAddress = (emailAddress) => {
    setValidatingEmail(true);
    setEmailValidationMessage("");
    (async () => {
      // If it's a new signup, don't allow the same email address
      if (newSignup) {
        const existingPartner = await getPartnerForEmail({ email: emailAddress.trim() });
        if (!existingPartner?.error && existingPartner?.data?.objectId !== businessInfo?.id) {
          setEmailValidationMessage("An account already exists with this email address");
        }
      }

      // If it's an existing signup... do we even allow setting this field?
      setValidatingEmail(false);
    })();
  };

  useEffect(() => {
    validateForm();
  }, [formState]);

  const getDisplayHoursForDay = (day) => {
    const dayHours = openingHours[day];

    if (!dayHours) {
      console.error(`No opening hours set for day: ${day}`);
      return "Not set";
    }

    if (!dayHours?.isOpen) {
      return "Closed";
    }

    const shiftStrings = dayHours.shifts
      .map((shift) => {
        if (shift?.start === null || shift?.end === null) {
          return "";
        }

        return `${formatTimeFromInt(shift?.start)} - ${formatTimeFromInt(shift?.end)}`;
      })
      .filter((string) => string !== "");

    if (!shiftStrings || shiftStrings.length === 0) {
      // console.error("Invalid opening hours", dayHours);
      return "Not set";
    }

    return (
      <>
        {shiftStrings.map((shiftString) => (
          <Box key={shiftString}>{shiftString}</Box>
        ))}
      </>
    );
  };

  return (
    <Box>
      <Box style={{ marginBottom: "70px" }}>
        <Box style={{ marginBottom: "20px" }}>
          <h1>Finalise the application setup</h1>
        </Box>
        <Box className="paragraph-large">Review and sign to generate the final contract.</Box>
      </Box>
      {validationErrors.length !== 0 && (
        <Box className="error-box">
          <Box style={{ display: "inline-flex", columnGap: "5px" }}>
            <CriticalWarningSVG
              style={{
                // position: "absolute",
                // marginLeft: "3px",
                marginTop: "8px",
                height: "24px",
              }}
            />
            <Box style={{ marginBottom: "15px" }} className="error-text">
              Cannot submit the form
            </Box>
          </Box>
          <Box style={{ marginBottom: "20px", fontWeight: "bold" }} className="paragraph-regular">
            Please fix the following errors to create the contract:
          </Box>
          {validationErrors.map((error, i) => (
            <Box style={{ marginBottom: "10px" }} className="paragraph-small">
              {error}
            </Box>
          ))}
        </Box>
      )}
      <Box style={{ marginBottom: "50px" }}>
        <Box style={{ marginBottom: "30px" }}>
          <h3>Profile</h3>
        </Box>
        <LabelledData label="Partner type" value={newSignup ? "New" : "Existing"} />
      </Box>

      <Box style={{ marginBottom: "50px" }}>
        <Box style={{ marginBottom: "14px" }}>
          <h3>Services</h3>
        </Box>
        <Box style={{ display: "flex", flexWrap: "wrap" }} className="flex-space-small">
          {services.takeaway.takeawayIAO && (
            <Pill style={{ fontWeight: "regular" }}>Takeaway orders</Pill>
          )}
          {services.takeaway.takeawayVouchers && (
            <Pill style={{ container: { fontWeight: "400" } }}>Takeaway</Pill>
          )}
          {services.dineIn.dineInVouchers && (
            <Pill style={{ container: { fontWeight: "400" } }}>Dine-in vouchers</Pill>
          )}
          {services.dineIn.tableOrders && (
            <Pill style={{ container: { fontWeight: "400" } }}>Table orders</Pill>
          )}
          {services.whiteLabel.tableOrdering && (
            <Pill style={{ container: { fontWeight: "400" } }}>Order Direct table orders</Pill>
          )}
          {services.whiteLabel.takeaway && (
            <Pill style={{ container: { fontWeight: "400" } }}>Order Direct takeaway</Pill>
          )}
          {services.whiteLabel.delivery && (
            <Pill style={{ container: { fontWeight: "400" } }}>Order Direct delivery</Pill>
          )}
          {services?.bookings?.enabled && (
            <Pill style={{ container: { fontWeight: "400" } }}>
              EC Bookings: {getLabelForValue(services?.bookings?.plan, eatclubBookingsPlans())}
            </Pill>
          )}
        </Box>
      </Box>

      <Box style={{ marginBottom: "50px" }}>
        <Box style={{ marginBottom: "14px" }}>
          <h3>Pricing</h3>
        </Box>
        <Box style={{ display: "inline-flex", flexWrap: "wrap" }} className="flex-space-large">
          {(services.dineIn.dineInVouchers || services.dineIn.tableOrders) && (
            <LabelledData
              label="Dine-in"
              value={
                formState?.fees?.dineIn?.pricingType === "percent"
                  ? `${fees.dineIn.onlineAmount}% per online order`
                  : `${formatCurrency(formState?.fees?.dineIn?.amount)} ${getLabelForValue(
                      formState?.fees?.dineIn?.pricingType,
                      dineInPricingTypes(),
                    ).toLowerCase()}`
              }
            />
          )}
          {!isECPayRegion(formState?.businessInfo?.eatclubRegion) &&
            services.dineIn.dineInVouchers &&
            fees.dineIn.differentOnlinePricing && (
              <LabelledData
                label="In-App Ordering"
                value={`${fees.dineIn.onlineAmount}% per online order`}
              />
            )}
          {services.takeaway.takeawayVouchers && (
            <LabelledData
              label="Takeaway"
              value={
                formState?.fees?.takeawayOrders?.pricingType === "percent"
                  ? `${fees.takeawayOrders.onlineAmount}% per online order`
                  : `${formatCurrency(formState?.fees?.takeawayOrders?.amount)} ${getLabelForValue(
                      formState?.fees?.takeawayOrders?.pricingType,
                      takeawayPricingTypes(),
                    ).toLowerCase()}`
              }
            />
          )}
          {formState?.fees?.takeawayOrders?.pricingType !== "percent" &&
            services.takeaway.takeawayVouchers &&
            fees.takeawayOrders.differentOnlinePricing && (
              <LabelledData
                label="Online Takeaway"
                value={
                  formState?.fees?.takeawayOrders?.pricingType === "percent"
                    ? `${fees.takeawayOrders.onlineAmount}% per online order`
                    : `${fees.takeawayOrders.onlineAmount}% ${getLabelForValue(
                        formState?.fees?.takeawayOrders?.pricingType,
                        takeawayPricingTypes(),
                      ).toLowerCase()}`
                }
              />
            )}
          {!bookingsOnly && (
            <LabelledData
              label="Setup"
              value={`${formatCurrency(formState?.fees?.setup?.amount, false)} ${getLabelForValue(
                formState?.fees?.setup?.frequency,
                setupPaymentFrequencies(),
              ).toLowerCase()}`}
            />
          )}
          {!bookingsOnly && (
            <LabelledData
              label="Membership"
              value={`${formatCurrency(
                formState?.fees?.membership?.amount,
                false,
              )} ${getLabelForValue("monthly", membershipPaymentFrequencies()).toLowerCase()}`}
            />
          )}
          {services?.bookings?.enabled && (
            <LabelledData
              label="EC Bookings"
              value={getBookingsFee(formState?.services?.bookings?.plan)}
            />
          )}
          {useWhiteLabel() && (
            <LabelledData
              label="Order Direct transaction fee"
              value={`${formState.fees.whiteLabel?.amount}%`}
            />
          )}
        </Box>
      </Box>

      <Box style={{ marginBottom: "50px" }}>
        <Box style={{ marginBottom: "14px" }}>
          <h3>Opening hours</h3>
        </Box>
        <table style={{ maxWidth: "480px" }}>
          <tbody>
            <tr>
              <td>Monday</td>
              <td>{getDisplayHoursForDay("monday")}</td>
            </tr>
            <tr>
              <td>Tuesday</td>
              <td>{getDisplayHoursForDay("tuesday")}</td>
            </tr>
            <tr>
              <td>Wednesday</td>
              <td>{getDisplayHoursForDay("wednesday")}</td>
            </tr>
            <tr>
              <td>Thursday</td>
              <td>{getDisplayHoursForDay("thursday")}</td>
            </tr>
            <tr>
              <td>Friday</td>
              <td>{getDisplayHoursForDay("friday")}</td>
            </tr>
            <tr>
              <td>Saturday</td>
              <td>{getDisplayHoursForDay("saturday")}</td>
            </tr>
            <tr>
              <td>Sunday</td>
              <td>{getDisplayHoursForDay("sunday")}</td>
            </tr>
          </tbody>
        </table>
      </Box>

      <Box style={{ marginBottom: "50px" }}>
        <Box style={{ marginBottom: "14px" }}>
          <h3>Operational details</h3>
        </Box>
        <Box style={{ display: "inline-flex", flexWrap: "wrap" }} className="flex-space-large">
          <LabelledData
            label="Tablet required"
            value={getLabelForValue(operations.tabletRequired, yesNo())}
          />
          <LabelledData
            label="Preparation time"
            value={
              getLabelForValue(operations.preparationTime, preparationTimes()) ||
              `${operations.preparationTime} mins`
            }
          />
        </Box>
      </Box>

      {deals.length > 0 && (
        <Box style={{ marginBottom: "50px" }}>
          <Box style={{ marginBottom: "14px" }}>
            <h3>Deals</h3>
          </Box>
          <Box>
            <DealGrid deals={deals} />
          </Box>
        </Box>
      )}

      {useWhiteLabel() && (
        <Box style={{ marginBottom: "50px" }}>
          <Box style={{ marginBottom: "14px" }}>
            <h3>Whitelabel</h3>
          </Box>
          <Box style={{ display: "inline-flex", gap: "20px" }}>
            {orderDirect?.logo && (
              <LabelledData label="Logo" value={<img src="" alt="Company logo" />} />
            )}
            <LabelledData
              label="Brand colour"
              value={
                <Box
                  style={{
                    backgroundColor: orderDirect?.brandColor,
                    width: "50px",
                    height: "50px",
                    borderRadius: "8px",
                  }}
                />
              }
            />
            <LabelledData label="Amount of tables" value={orderDirect?.tableAmount} />
          </Box>
        </Box>
      )}

      {/*<Box style={{marginBottom: "50px"}}>*/}
      {/*  <Box style={{marginBottom: "14px"}}>*/}
      {/*    <h3>Marketing</h3>*/}
      {/*  </Box>*/}
      {/*  {marketing.emailFeature ||*/}
      {/*  marketing.emailMention ||*/}
      {/*  marketing.facebookPost ||*/}
      {/*  marketing.pushNotifications ||*/}
      {/*  marketing.fullPackage ? (*/}
      {/*    <Box display="flex" flexWrap="wrap" className="flex-space-small">*/}
      {/*      {marketing.emailFeature && <Pill>Email feature</Pill>}*/}
      {/*      {marketing.emailMention && <Pill>Email mention</Pill>}*/}
      {/*      {marketing.facebookPost && <Pill>Facebook post</Pill>}*/}
      {/*      {marketing.pushNotifications && <Pill>Push notifications</Pill>}*/}
      {/*      {marketing.fullPackage && <Pill>Full package</Pill>}*/}
      {/*    </Box>*/}
      {/*  ) : (*/}
      {/*    <Box>No marketing options selected</Box>*/}
      {/*  )}*/}
      {/*</Box>*/}

      <Box style={{ marginBottom: "50px" }}>
        <Box style={{ marginBottom: "14px" }}>
          <h3>Settlement details</h3>
        </Box>
        <CompletedItem
          completed={bankDetailsValid()}
          label="Transfer details added"
          style={{ marginBottom: "10px" }}
        />
        {/*<CompletedItem TODO re-add when credit card is added*/}
        {/*  completed={creditCardDetailsValid()}*/}
        {/*  label="Credit card added"*/}
        {/*  style={{ marginBottom: "10px" }}*/}
        {/*/>*/}
        <CompletedItem
          completed={stripeAccountConnected()}
          label="Collection details added"
          style={{ marginBottom: "10px" }}
        />
      </Box>

      {!isComplete && (
        <>
          <Box style={{ marginBottom: "50px", maxWidth: "480px" }}>
            <Box style={{ marginBottom: "14px" }}>
              <h3>Login details for the EatClub partner apps</h3>
            </Box>
            <Box style={{ marginBottom: "10px" }}>
              <TextField
                label="Username (email address)"
                value={inputUsername}
                onBlur={(e) => {
                  setFieldValue("username", trim(inputUsername));
                  validateEmailAddress(trim(e.target.value));
                }}
                onChange={(value) => {
                  setUsername(value);
                  setValidationErrors([]);
                }}
                style={{ width: "100%" }}
                disabled={!newSignup || validatingEmail}
                validationMessage={emailValidationMessage}
              />
              {emailValidationMessage && (
                <Box className="error-text-small">{emailValidationMessage}</Box>
              )}
            </Box>
            <TextField
              label="Password"
              type={TYPES.PASSWORD}
              value={inputPassword}
              onBlur={() => {
                setFieldValue("password", inputPassword);
              }}
              onChange={(value) => {
                setPassword(value);
                setValidationErrors([]);
              }}
            />
          </Box>
          {/*<Box style={{ marginBottom: "50px" }}>*/}
          {/*  <Box style={{ marginBottom: "14px" }}>*/}
          {/*    <h3>Sign</h3>*/}
          {/*  </Box>*/}
          {/*  <Box>*/}
          {/*    <SignatureCanvas*/}
          {/*      ref={signatureCanvasRef}*/}
          {/*      clearOnResize={false}*/}
          {/*      canvasProps={{*/}
          {/*        className: "signature-canvas",*/}
          {/*      }}*/}
          {/*    />*/}
          {/*    <Button*/}
          {/*      type="text"*/}
          {/*      className="brand-link-color"*/}
          {/*      text="clear signature"*/}
          {/*      onClick={() => signatureCanvasRef.current.clear()}*/}
          {/*    />*/}
          {/*  </Box>*/}
          {/*</Box>*/}
          <Box style={{ marginBottom: "100px", maxWidth: "480px" }}>
            <Box style={{ marginBottom: "20px" }} className="paragraph-small">
              In the event the Services are terminated, the venue is to return all tech equipment
              (tablet and charging components) by mail to EatClub within 14 days to the address
              specified by the EatClub representative. Failure to return will result in a tablet fee
              charge of $200.
            </Box>

            <Box style={{ marginBottom: "20px" }} className="paragraph-small">
              This Agreement, which incorporates the Contract Details, Glossary and EatClub Terms &
              Conditions constitutes the entire agreement between the parties. This Agreement may be
              executed electronically and in any number of counterparts, each of which, when
              executed, is an original. Those counterparts together make one instrument. The person
              signing the Agreement for the Restaurant represents and warrants that he or she is
              authorized to act on behalf of the Restaurant for the purposes of this Agreement.
            </Box>
            <Box style={{ marginBottom: "20px" }} className="paragraph-small">
              <a
                style={{ textDecoration: "underline", color: "#313131" }}
                href="https://signup.eatclub.com.au/terms_conditions"
                target="_blank"
                rel="noreferrer noopener"
              >
                https://signup.eatclub.com.au/terms_conditions
              </a>
            </Box>
            <Box style={{ marginBottom: "20px" }} className="paragraph-small">
              <a
                href="https://stripe.com/connect-account/legal/full"
                style={{
                  textDecoration: "underline",
                  color: "#313131",
                  "&:hover": { color: "black" },
                }}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => e.stopPropagation()}
              >
                Stripe Connected Account Agreement
              </a>
            </Box>
          </Box>
          <Box style={{ marginBottom: "50px" }} className="submit-box">
            <Box>
              <Box style={{ marginBottom: "16px" }}>
                <h3>Finalise the contract</h3>
              </Box>
              {validationErrors.length > 0 ? (
                <Box className="paragraph-regular">Please fix any errors before submitting</Box>
              ) : (
                <Box className="paragraph-regular">
                  Prepare contract and send link for the venue to sign
                </Box>
              )}
            </Box>
            <Box>
              <Button
                className="vertical-center"
                text="Submit application"
                onClick={handleSubmit}
                loading={isLoading || isSubmitting}
                disabled={!formState.password || validationErrors.length !== 0 || isSubmitting}
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

Finalise.propTypes = {
  formState: formStateType.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  setPageId: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isComplete: PropTypes.bool.isRequired,
};
