import { Box } from "@eatclub-apps/ec-component-library";
import PropTypes from "prop-types";
import React from "react";
import useStyles from "./DetailsGroupStyles";

export const DetailsGroup = ({ items = [] }) => {
  const classes = useStyles();

  return (
    <Box className={classes.items}>
      {items.map((item) => (
        <Box key={item?.label} className={classes.itemRow}>
          <Box className={classes.itemLabel}>{item.label}</Box>
          <Box>{item.value}</Box>
        </Box>
      ))}
    </Box>
  );
};

DetailsGroup.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.node,
      ]),
    }),
  ),
};
