import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(
  () => ({
    termsWrapper: {
      lineHeight: "1.8",
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    },
  }),
  { name: "TermsAgreement" },
);

export default useStyles;
