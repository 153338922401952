import { Box } from "@eatclub-apps/ec-component-library";
import PropTypes from "prop-types";
import React from "react";
import { NavAnchor } from "./NavAnchor";
import useStyles from "./NavAnchorsStyles";

export const NavAnchors = ({ items = [], anchorsRef }) => {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      {items.map((anchor) => (
        <NavAnchor
          key={anchor.value}
          isActive={anchorsRef.activeSection === anchor.value}
          anchor={anchor}
          anchorRef={anchorsRef.anchors.current[anchor.value]}
        />
      ))}
    </Box>
  );
};

NavAnchors.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    }),
  ),
};
