import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(
  () => ({
    wrapper: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      gap: "22px",
    },
    title: {
      fontSize: "28px",
    },
    divider: {
      width: "100%",
      borderBottom: "1px solid #BDBDBD",
      marginBottom: "20px",
    },
    // Allows the scroll point to have an offset, unlike the title
    scrollAnchor: {
      position: "absolute",
      width: "100px",
      height: "120px",
      top: "-30px",
      userSelect: "none",
      pointerEvents: "none",
    },
  }),
  { name: "Section" },
);

export default useStyles;
