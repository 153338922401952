import { useEffect, useMemo, useState } from "react";

/**
 * Return whether a html entity is visible on the screen
 * Takes an optional callback for when the visibility state changes
 * @param ref
 * @param onChange
 * @returns {boolean}
 */
export default function useOnScreen(ref, onChange = () => {}) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(
    () => new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting)),
    [ref],
  );

  useEffect(() => {
    if (ref?.current) {
      observer.observe(ref.current);
    }
    return () => observer.disconnect();
  }, [ref?.current]);

  useEffect(() => {
    onChange(isIntersecting);
  }, [isIntersecting]);

  return isIntersecting;
}

// export function useOnScreenMultiple(refs, onChange = () => {}) {
//   const [isIntersecting, setIntersecting] = useState(false);
//
//   console.log("refsss", refs);
//
//   const observer = useMemo(
//     () => new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting)),
//     [refs],
//   );
//
//   useEffect(() => {
//     Object.keys(refs).forEach((refName) => {
//       const ref = refs[refName];
//       if (ref?.current) {
//         observer.observe(ref.current);
//       }
//     });
//     return () => observer.disconnect();
//   }, [refs]);
//
//   useEffect(() => {
//     onChange(isIntersecting);
//   }, [isIntersecting]);
//
//   return isIntersecting;
// }
