import { Box } from "@eatclub-apps/ec-component-library";
import React from "react";
import LogoImage from "../../../assets/ec_logo.svg";
import useStyles from "./HeaderStyles";

export const Header = () => {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.topHeader}>
        <Box className={classes.logo}>
          <LogoImage style={{ color: "#363636" }} />
        </Box>
      </Box>

      <Box className={classes.divider} />

      <Box className={classes.mainHeader}>
        <Box className={classes.mainHeaderContent}>
          <Box style={{ fontSize: "80px", fontWeight: "500" }}>Sign up to EatClub</Box>
          <Box style={{ fontSize: "24px" }}>
            Review and sign to access EatClub&apos;s dynamic pricing platform
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

Header.propTypes = {};
