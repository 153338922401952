import { Button, Box } from "@eatclub-apps/ec-component-library";
import React, { createRef, useEffect, useRef, useState } from "react";
import { DealGrid } from "../../components/DealList/DealGrid";
import { getDefaultFormValues } from "../../data/DefaultFormValues";
import { getLabelForValue, preparationTimes, yesNo } from "../../data/ValueLists";
import { getPartnerSignupForId, listBDMsRequest } from "../../graphql/api-client";
import { formatTimeFromInt, joinWords } from "../../helpers/Helpers";
import useNavAnchors from "../../helpers/Hooks/useNavAnchors";
import { COLORS } from "../../theme";
import { DetailsGroup } from "./DetailsGroup/DetailsGroup";
import { Header } from "./Header/Header";
import { NavAnchors } from "./NavAnchors/NavAnchors";
import { Section } from "./Section/Section";
import { Services } from "./Services/Services";
import { TermsAgreement } from "./TermsAgreement/TermsAgreement";

export const UserSign = () => {
  const [error, setError] = useState("");

  const [signup, setSignup] = useState(null);
  const [bdmList, setBdmList] = useState([]);

  const useWhiteLabel =
    signup?.services?.whiteLabel?.tableOrdering ||
    signup?.services?.whiteLabel?.takeaway ||
    signup?.services?.whiteLabel?.delivery;

  const hasDeals = signup?.deals?.length > 0;
  const hasBookings = signup?.services?.bookings?.enabled;

  useEffect(() => {
    (async () => {
      let formValues = getDefaultFormValues();
      const { data: partnerSignup, error: responseError } = await getPartnerSignupForId(
        // TODO get signup through URL
        // partner.objectId,
        "BA3BBADA-599F-40BD-B770-6FDC86D9D11E",
      );

      if (responseError) {
        setError(responseError);
        return;
      }

      formValues = { ...formValues, ...partnerSignup };

      // Ensure we correctly tell whether it's a new signup or not
      if (partnerSignup?.businessInfo?.id) {
        formValues.newSignup = false;
      }

      // Ensure we correctly tell whether it's a new signup or not
      // TODO detect if this signup has already been completed
      // if (partner.type === SEARCH_RESULT_TYPE.COMPLETED) {
      //   formValues.newSignup = false;
      //   formValues.isComplete = true;
      // }

      // Fetch partner from backend

      console.log("signup", formValues);
      setSignup(formValues);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const bdms = await listBDMsRequest();

      if (bdms.data) {
        setBdmList(bdms?.data);
      }
    })();
  }, []);

  // TODO put all these lists into a useMemo

  const businessDetails = [
    { label: "Legal business name", value: signup?.businessInfo?.legalName },
    { label: "Trading name", value: signup?.businessInfo?.tradingName },
    { label: "Business phone number", value: signup?.businessInfo?.phoneNumber },
    { label: "Business type", value: signup?.businessInfo?.businessType },
    { label: "ABN", value: signup?.businessInfo?.abn },
    { label: "ACN", value: signup?.businessInfo?.acn },
    { label: "Business website", value: signup?.businessInfo?.website },
    {
      label: "Business address",
      value: joinWords(
        [
          joinWords([signup?.businessInfo?.addressLine1, signup?.businessInfo?.addressLine2]),
          signup?.businessInfo?.city,
          joinWords([signup?.businessInfo?.region, signup?.businessInfo?.postcode]),
        ],
        ", ",
      ),
    },
    { label: "EatClub region", value: signup?.businessInfo?.eatclubRegion },
  ];

  const authorityDetails = [
    {
      label: "Authority's full name",
      value: joinWords([signup?.accountHolder?.firstName, signup?.accountHolder?.lastName]),
    },
    { label: "Email", value: signup?.accountHolder?.email },
    { label: "Mobile number", value: signup?.accountHolder?.phoneNumber },
    { label: "Position/title", value: signup?.accountHolder?.jobTitle },
    { label: "Date of birth", value: signup?.accountHolder?.dateOfBirth },
    {
      label: "Home address",
      value: joinWords(
        [
          joinWords([signup?.accountHolder?.addressLine1, signup?.accountHolder?.addressLine2]),
          signup?.accountHolder?.city,
          joinWords([signup?.accountHolder?.region, signup?.accountHolder?.postcode]),
        ],
        ", ",
      ),
    },
    // { label: "Daily contact name", value: signup?.accountHolder?.firstName },
    // { label: "Daily contact number", value: signup?.accountHolder?.firstName },
    // { label: "Daily contact email", value: signup?.accountHolder?.firstName },
  ];

  const anchors = [
    { label: "Business details", value: "details" },
    { label: "Signing authority", value: "signingAuthority" },
    { label: "Services", value: "services" },
    { label: "Communications", value: "communications" },
    { label: "Opening hours", value: "openingHours" },
    { label: "Operational details", value: "operationalDetails" },
    hasDeals && { label: "Deals", value: "deals" },
    hasBookings && { label: "Bookings", value: "bookings" },
    useWhiteLabel && { label: "Order direct", value: "orderDirect" },
    useWhiteLabel && { label: "Settlement details", value: "settlement" },
    { label: "Support", value: "support" },
    { label: "Terms and conditions", value: "terms" },
  ].filter((item) => !!item);

  const communicationsEmails = [
    signup?.communication?.invoices?.isUsed && {
      label: "Invoices and payment summaries",
      value: joinWords(signup?.communication.invoices.emailAddresses, ", "),
    },
    signup?.communication?.marketing?.isUsed && {
      label: "Marketing",
      value: joinWords(signup?.communication.marketing.emailAddresses, ", "),
    },
    signup?.communication?.orders?.isUsed && {
      label: "Order and booking confirmations",
      value: joinWords(signup?.communication.orders.emailAddresses, ", "),
    },
    signup?.communication?.oppos?.isUsed && {
      label: "Daily opportunities",
      value: joinWords(signup?.communication.oppos.emailAddresses, ", "),
    },
  ].filter((item) => !!item);

  const getDisplayHoursForDay = (day) => {
    const dayHours = signup?.openingHours?.[day];

    if (!dayHours) {
      // console.error(`No opening hours set for day: ${day}`);
      return "Not set";
    }

    if (!dayHours?.isOpen) {
      return "Closed";
    }

    const shiftStrings = dayHours.shifts
      .map((shift) => {
        if (shift?.start === null || shift?.end === null) {
          return "";
        }

        return `${formatTimeFromInt(shift?.start)} - ${formatTimeFromInt(shift?.end)}`;
      })
      .filter((string) => string !== "");

    if (!shiftStrings || shiftStrings.length === 0) {
      // console.error("Invalid opening hours", dayHours);
      return "Not set";
    }

    return (
      <>
        {shiftStrings.map((shiftString) => (
          <Box key={shiftString}>{shiftString}</Box>
        ))}
      </>
    );
  };

  const openingHoursTimes = [
    { label: "Monday", value: getDisplayHoursForDay("monday") },
    { label: "Tuesday", value: getDisplayHoursForDay("tuesday") },
    { label: "Wednesday", value: getDisplayHoursForDay("wednesday") },
    { label: "Thursday", value: getDisplayHoursForDay("thursday") },
    { label: "Friday", value: getDisplayHoursForDay("friday") },
    { label: "Saturday", value: getDisplayHoursForDay("saturday") },
    { label: "Sunday", value: getDisplayHoursForDay("sunday") },
  ];

  const paymentDetails = [
    {
      label: "Bank account name",
      value: signup?.paymentDetails.bankAccountName,
    },
    {
      label: "Bank account Number",
      value: signup?.paymentDetails.bankAccountNumber,
    },
    {
      label: "BSB",
      value: signup?.paymentDetails.bsb,
    },
    {
      label: "Credit card number ",
      value: signup?.paymentDetails.creditCardName,
    },
  ].filter((item) => !!item);

  const whiteLabelDetails = [
    {
      label: "Brand colour",
      value: (
        <Box
          style={{
            backgroundColor: signup?.orderDirect?.brandColor,
            width: "50px",
            height: "50px",
            borderRadius: "8px",
          }}
        />
      ),
    },
    {
      label: "Amount of tables",
      value: signup?.orderDirect?.tableAmount,
    },
    {
      label: "URL",
      value: `order-direct.com.au/${signup?.orderDirect?.slug}`,
    },
  ].filter((item) => !!item);

  const supportDetails = [
    {
      label: "Login email",
      value: signup?.username,
    },
    // {
    //   label: "Go-live date",
    //   value: '',
    // },
    {
      label: "Account manager",
      value: bdmList?.find((bdm) => bdm?.objectId === signup?.bdm)?.name,
    },
  ];

  const navAnchors = useNavAnchors(anchors);
  return (
    <Box className="user-sign">
      <Header />

      <Box style={{ display: "flex", padding: "64px" }}>
        <Box
          style={{
            width: "238px",
            position: "sticky",
            margin: "-64px 0 0 -20px",
            padding: "64px 20px 20px 20px",
            height: "fit-content",
            top: 0,
            color: COLORS.SMOKE,
          }}
        >
          <NavAnchors items={anchors} anchorsRef={navAnchors} />
        </Box>
        <Box
          style={{
            width: "100%",
            maxWidth: "700px",
            marginBottom: "100px",
            display: "flex",
            gap: "70px",
            flexDirection: "column",
          }}
        >
          <Section
            title="Business details"
            id="details"
            navRef={navAnchors.anchors.current.details}
          >
            <DetailsGroup items={businessDetails} />
          </Section>

          <Section
            title="Signing authority"
            id="signingAuthority"
            navRef={navAnchors.anchors.current.signingAuthority}
          >
            <DetailsGroup items={authorityDetails} />
          </Section>

          <Section title="Services" id="services" navRef={navAnchors.anchors.current.services}>
            <Services signup={signup} />
          </Section>

          <Section
            title="Communications"
            id="communications"
            navRef={navAnchors.anchors.current.communications}
          >
            <DetailsGroup items={communicationsEmails} />
          </Section>

          <Section
            title="Opening hours"
            id="openingHours"
            navRef={navAnchors.anchors.current.openingHours}
          >
            <DetailsGroup items={openingHoursTimes} />
          </Section>

          <Section
            title="Operational details"
            id="operationalDetails"
            navRef={navAnchors.anchors.current.operationalDetails}
          >
            <DetailsGroup
              items={[
                {
                  label: "Tablet required",
                  value: getLabelForValue(signup?.operations?.tabletRequired, yesNo()),
                },
                {
                  label: "Preparation time",
                  value:
                    getLabelForValue(signup?.operations?.preparationTime, preparationTimes()) ||
                    `${signup?.operations?.preparationTime} mins`,
                },
              ]}
            />
          </Section>

          {hasDeals > 0 && (
            <Section title="Deals" id="deals" navRef={navAnchors.anchors.current.deals}>
              <DealGrid deals={signup?.deals} />
            </Section>
          )}

          {/*TODO only show if venue is using EC Bookings*/}
          <Section title="Bookings" id="bookings" navRef={navAnchors.anchors.current?.bookings}>
            <DetailsGroup
              items={[
                {
                  label: "Bookings URL",
                  value: `ecbookings.com/${signup?.services?.bookings?.slug}`,
                },
                { label: "Operations email", value: signup?.services?.bookings?.email },
              ]}
            />
          </Section>

          {useWhiteLabel && (
            <Section
              title="Order direct"
              id="orderDirect"
              navRef={navAnchors.anchors.current.orderDirect}
            >
              <DetailsGroup items={whiteLabelDetails} />
            </Section>
          )}

          <Section
            title="Settlement details"
            id="settlement"
            navRef={navAnchors.anchors.current?.settlement}
          >
            <DetailsGroup items={paymentDetails} />
          </Section>

          <Section title="Support" id="support" navRef={navAnchors.anchors.current?.support}>
            <DetailsGroup items={supportDetails} />
          </Section>

          <Section
            title="Terms and conditions"
            id="terms"
            navRef={navAnchors.anchors.current.terms}
          >
            <TermsAgreement />
          </Section>
        </Box>
      </Box>
    </Box>
  );
};

UserSign.propTypes = {};
